import { useEffect } from 'react';
import useFetch from 'react-fetch-hook';
import { toast } from 'react-toastify';

import { Layout, Spinner, ThankYou } from '../components';

import { ADMIN_URL } from '../App';

const ThankYouPage = () => {
  const { isLoading, data, error } = useFetch(`${ADMIN_URL}/page-settings?slug=settings`);

  useEffect(() => {
    if (error) toast.error('Data loading error. Please reload the page!');
  }, [error]);

  if (isLoading) return <Spinner />;

  return (
    <Layout page="thank-you-page" settings={data[0].acf}>
      <section>
        <ThankYou />
      </section>
    </Layout>
  );
};

export default ThankYouPage;
