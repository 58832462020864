import './ratingStars.scss';

const RatingStars = () => (
  <div className="rating-stars">
    <img src="./assets/images/icons/star.svg" alt="Star" />
    <img src="./assets/images/icons/star.svg" alt="Star" />
    <img src="./assets/images/icons/star.svg" alt="Star" />
    <img src="./assets/images/icons/star.svg" alt="Star" />
    <img src="./assets/images/icons/star.svg" alt="Star" />
  </div>
);

export default RatingStars;
