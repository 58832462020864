import { useEffect } from 'react';
import useFetch from 'react-fetch-hook';
import { toast } from 'react-toastify';

import { Layout, Seo, Spinner, ContentBlock } from '../components';

import { ADMIN_URL } from '../App';

const TermsOfUse = () => {
  const { isLoading, data, error } = useFetch(`${ADMIN_URL}/policy-pages?slug=terms-of-use`);

  const getSettings = useFetch(`${ADMIN_URL}/page-settings?slug=settings`);

  useEffect(() => {
    if (error || getSettings.error) toast.error('Data loading error. Please reload the page!');
  }, [error, getSettings.error]);

  if (isLoading || getSettings.isLoading) return <Spinner />;

  return (
    <Layout pageId="terms-of-use" settings={getSettings.data[0].acf}>
      <Seo data={data[0]?.yoast_head_json} />
      <section>
        <ContentBlock title={data[0]?.title.rendered} content={data[0]?.content?.rendered} />
      </section>
    </Layout>
  );
};

export default TermsOfUse;
