import './about.scss';

const About = ({ title, content, photo }) => (
  <section className="about">
    <div className="container">
      <div className="about__content">
        <h2 className="section-title">
          {title}
          <br />
          Experts
        </h2>

        <div dangerouslySetInnerHTML={{ __html: content }} />
      </div>

      <div className="about__image">
        <img src={photo} alt={title} />
      </div>
    </div>
  </section>
);

export default About;
