const slider_settings = (slidesToShow, isVertical = false) => ({
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: slidesToShow,
  slidesToScroll: 1,
  vertical: isVertical,
  verticalSwiping: isVertical,
  responsive: [
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      ...(!isVertical && {
        breakpoint: 769,
        settings: {
          slidesToShow: 1,
        },
      }),
    },
  ],
});

export default slider_settings;
