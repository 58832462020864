import { RatingStars } from '../../components';

import './satisfactionGuarantee.scss';

const SatisfactionGuarantee = ({ data }) => (
  <section className="satisfaction-guarantee">
    <div className="container">
      <h2 className="section-title">
        100% Satisfaction
        <br />
        Guaranteed
      </h2>

      <div className="satisfaction-guarantee__list">
        {data?.map((el, index) => (
          <div className="satisfaction-guarantee__card" key={index}>
            <img src={el.logo} alt={`Rating #${index}`} />

            <RatingStars />
          </div>
        ))}
      </div>
    </div>
  </section>
);

export default SatisfactionGuarantee;
