import { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import useFetch from 'react-fetch-hook';

import PageTemplate from './pages/PageTemplate';
import PageNotFound from './pages/404';
import Book from './pages/Book';
import PrivacyPolicy from './pages/PrivacyPolicy';
import AccessibilityStatement from './pages/AccessibilityStatement';
import TermsOfUse from './pages/TermsOfUse';
import ThankYouPage from './pages/ThankYouPage';

import { Spinner } from './components';

export const ADMIN_URL = 'https://admin.defenderappliancerepair.com/wp-json/wp/v2';

const App = () => {
  const { isLoading, data, error } = useFetch(`${ADMIN_URL}/pages`);

  useEffect(() => {
    if (error) toast.error('Data loading error. Please reload the page!');
  }, [error]);

  if (isLoading) return <Spinner />;

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate replace to="/appliance-repair" />} />

        {data.map((page) => (
          <Route key={page.id} path={`/${page.slug}`} element={<PageTemplate />} />
        ))}

        <Route path="/book-now" element={<Book />} />
        <Route path="/thank-you" element={<ThankYouPage />} />

        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/accessibility-statement" element={<AccessibilityStatement />} />
        <Route path="/terms-of-use" element={<TermsOfUse />} />

        <Route path="/404" element={<PageNotFound />} />

        <Route path="*" element={<Navigate replace to="/404" />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
