import './counter.scss';

const Counter = ({ data }) => (
  <section className="counter">
    <div className="container">
      <div className="counter__wrapper">
        {data?.map((el, index) => (
          <div className="counter__item" key={index}>
            <span>{el.number}</span>

            <span>{el.title}</span>
          </div>
        ))}
      </div>
    </div>
  </section>
);

export default Counter;
