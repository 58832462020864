import Slider from 'react-slick';
import { Link } from 'react-router-dom';

import slider_settings from '../../static/slider_settings';

import static_content from '../../static/content';

import './services.scss';

const Services = () => {
  return (
    <section className="services">
      <div className="container">
        <h2 className="section-title">Appliance Serviced</h2>

        <Slider className="services__list" {...slider_settings(4)}>
          {static_content.services?.map((service, index) => (
            <ServiceCard {...{ service, key: index }} />
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default Services;

const ServiceCard = ({ service }) => (
  <Link to={service.url} className="services__list--card">
    <div>
      <img src={service.icon} alt={service.title} />

      <h4>{service.title}</h4>
    </div>
  </Link>
);
