import { useState, useEffect } from 'react';
import useFetch from 'react-fetch-hook';
import { toast } from 'react-toastify';

import { Layout, Seo, Spinner } from '../components';

import {
  Hero,
  WhyWe,
  Services,
  SatisfactionGuarantee,
  About,
  Counter,
  Reviews,
  Areas,
  Brands,
  CallToAction,
} from '../sections';

import { ADMIN_URL } from '../App';

const PageTemplate = () => {
  const [pageData, setPageData] = useState();
  const [settings, setSettings] = useState();
  const [areas, setAreas] = useState();
  const slug = window.location.pathname.split('/')[1];

  const { isLoading, data, error } = useFetch(`${ADMIN_URL}/pages?slug=${slug}`);

  const getSettings = useFetch(`${ADMIN_URL}/page-settings?slug=settings`);
  const getAreas = useFetch(`${ADMIN_URL}/page-settings?slug=areas`);

  useEffect(() => {
    if (data) setPageData(data[0]);
  }, [data]);

  useEffect(() => {
    if (error || getSettings.error || getAreas.error) toast.error('Data loading error. Please reload the page!');
  }, [error, getSettings.error, getAreas.error]);

  useEffect(() => {
    if (getSettings.data) setSettings(getSettings.data[0].acf);
    if (getAreas.data) setAreas(getAreas.data[0].acf?.areas_list);
  }, [getSettings.data, getAreas.data]);

  if (isLoading || getSettings.isLoading || getAreas.isLoading) return <Spinner />;

  return (
    <Layout pageId={pageData?.slug} settings={settings}>
      <Seo data={pageData?.yoast_head_json} />
      <Hero awards={settings?.awards} slug={pageData?.title.rendered} phone={settings?.contacts.phone} />
      <WhyWe />
      <Services />
      <SatisfactionGuarantee data={settings?.satisfaction} />
      <About title={pageData?.title.rendered} content={pageData?.content.rendered} photo={pageData?.acf.hero_image} />
      <Counter data={settings?.counter} />
      <Reviews reviews={settings?.reviews} />
      <Areas areas={areas} />
      <Brands brands={settings?.brands} />
      <CallToAction phone={settings?.contacts.phone} />
    </Layout>
  );
};

export default PageTemplate;
