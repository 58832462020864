import './areas.scss';

const Areas = ({ areas }) => (
  <section className="areas">
    <div className="container">
      <h2 className="section-title">Areas We Serve</h2>

      <ul>
        {areas
          ?.sort(function (a, b) {
            if (a.location < b.location) return -1;

            return 1;
          })
          .map((area, index) => (
            <li key={index}>{area.location}</li>
          ))}
      </ul>
    </div>
  </section>
);

export default Areas;
