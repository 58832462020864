import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useFetch from 'react-fetch-hook';
import { toast } from 'react-toastify';

import { Layout, Spinner, BookingForm } from '../components';

import { ADMIN_URL } from '../App';

const Book = () => {
  const [settings, setSettings] = useState();

  const location = useLocation();

  const { isLoading, data, error } = useFetch(`${ADMIN_URL}/page-settings?slug=settings`);

  useEffect(() => {
    if (data) setSettings(data[0].acf);
  }, [data]);

  useEffect(() => {
    if (error) toast.error('Data loading error. Please reload the page!');
  }, [error]);

  if (isLoading) return <Spinner />;

  return (
    <Layout pageId="book-now" settings={settings}>
      <section className="booking">
        <div className="container">
          <h2 className="section-title">Book Now</h2>
          <BookingForm prevLocation={location.state?.prevLocation} />
        </div>
      </section>
    </Layout>
  );
};

export default Book;
