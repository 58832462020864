const cards = [
  {
    title: `Certified<br/>Technicians`,
    description: `Our technicians are certified, trained professionals. They're equipped with the best tools to ensure a timely, professional job.`,
    icon: './assets/images/icons/technicians.svg',
  },
  {
    title: `Affordable<br/>Price`,
    description:
      'Our customers will never have to worry about hidden fees or costly hidden charges. Our prices are clear and simple.',
    icon: './assets/images/icons/price.svg',
  },
  {
    title: `Exceptional Customer<br/>Service`,
    description: `Our customers are our top priority, and we want to make sure you're always happy with your experience.`,
    icon: './assets/images/icons/warranty.svg',
  },
  {
    title: `Same-Day & Next-Day Service`,
    description: `Don't wait another day to get your appliance repaired. Call us today to schedule an appointment!`,
    icon: './assets/images/icons/repair.svg',
  },
];

const services = [
  {
    title: 'Refrigerator Repair',
    icon: './assets/images/services/refrigerator.svg',
    url: '/refrigerator-repair',
  },
  {
    title: 'Freezer Repair',
    icon: './assets/images/services/freezer.svg',
    url: '/freezer-repair',
  },
  {
    title: 'Washer Repair',
    icon: './assets/images/services/washer.svg',
    url: '/washer-repair',
  },
  {
    title: 'Dryer Repair',
    icon: './assets/images/services/dryer.svg',
    url: '/dryer-repair',
  },
  {
    title: 'Dishwasher Repair',
    icon: './assets/images/services/dishwasher.svg',
    url: '/dishwasher-repair',
  },
  {
    title: 'Range Repair',
    icon: './assets/images/services/range.svg',
    url: '/range-repair',
  },
  {
    title: 'Oven Repair',
    icon: './assets/images/services/oven.svg',
    url: '/oven-repair',
  },
  {
    title: 'Microwave Repair',
    icon: './assets/images/services/microwave.svg',
    url: '/microwave-repair',
  },
];

const brands = [
  [
    'http://control.a-techrepair.com/wp-content/uploads/2022/08/logo2-111.png',
    'http://control.a-techrepair.com/wp-content/uploads/2022/08/logo2-777.png',
    'http://control.a-techrepair.com/wp-content/uploads/2022/08/logo2-888.png',
    'http://control.a-techrepair.com/wp-content/uploads/2022/08/logo2-999.png',
  ],
  [
    'http://control.a-techrepair.com/wp-content/uploads/2022/08/logo2-1000.png',
    'http://control.a-techrepair.com/wp-content/uploads/2022/08/logo2-2222.png',
    'http://control.a-techrepair.com/wp-content/uploads/2022/08/logo2-4444.png',
    'http://control.a-techrepair.com/wp-content/uploads/2022/08/logo2-LGLGLG.png',
  ],
  [
    'http://control.a-techrepair.com/wp-content/uploads/2022/08/logo2-thththt.png',
    'http://control.a-techrepair.com/wp-content/uploads/2022/08/w3-copy.png',
    'http://control.a-techrepair.com/wp-content/uploads/2022/08/w5-copy.png',
    'http://control.a-techrepair.com/wp-content/uploads/2022/08/w6-copy.png',
  ],
  [
    'http://control.a-techrepair.com/wp-content/uploads/2022/08/w7-copy.png',
    'http://control.a-techrepair.com/wp-content/uploads/2022/08/w9-copy.png',
    'http://control.a-techrepair.com/wp-content/uploads/2022/08/w10-copy.png',
    'http://control.a-techrepair.com/wp-content/uploads/2022/08/w12-copy.png',
  ],
  [
    'http://control.a-techrepair.com/wp-content/uploads/2022/08/w13-copy.png',
    'http://control.a-techrepair.com/wp-content/uploads/2022/08/w14-copy.png',
    'http://control.a-techrepair.com/wp-content/uploads/2022/08/w16-copy.png',
    'http://control.a-techrepair.com/wp-content/uploads/2022/08/w17-copy.png',
  ],
];

const static_content = {
  cards,
  services,
  brands,
};

export default static_content;
