import { NavLink } from 'react-router-dom';

import { RatingStars } from '../../components';

import './hero.scss';

const Hero = ({ awards, slug, phone }) => (
  <section className="hero">
    <div className="container">
      <div className="hero__content">
        <h1>
          Rated #1
          <br />
          {slug}
          <br />
          Service
          <br />
          <span>in Philadelphia</span>
        </h1>

        <div className="hero__content--awards">
          {awards?.map((award, index) => (
            <div key={index}>
              <img src={award.icon} alt={`award #${index + 1}`} />
            </div>
          ))}
        </div>

        <div className="hero__content--links">
          <NavLink
            to="/book-now"
            className="btn yellow"
            state={{ prevLocation: window.location.pathname.split('/')[1] }}
          >
            Book a Service
          </NavLink>

          <span>
            or call us <a href={`tel:${phone}`}>{phone}</a>
          </span>
        </div>
      </div>

      <div className="hero__ratings">
        <div className="hero__ratings--wrapper">
          <div className="hero__ratings--card">
            <img src="./assets/images/logos/g_logo.svg" alt="Google Rating" />

            <div>
              <span>Google Rating</span>
              <RatingStars />
            </div>
          </div>
          <div className="hero__ratings--card">
            <img src="./assets/images/logos/bbb_logo.svg" alt="BBB Rating" />

            <div>
              <span>BBB Rating</span>
              <RatingStars />
            </div>
          </div>
          <div className="hero__ratings--card">
            <img src="./assets/images/logos/yelp_logo.svg" alt="Yelp Rating" />

            <div>
              <span>Yelp Rating</span>
              <RatingStars />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Hero;
