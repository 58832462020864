import Slider from 'react-slick';

import { RatingStars } from '../../components';

import slider_settings from '../../static/slider_settings';

import './reviews.scss';

const Reviews = ({ reviews }) => (
  <section className="reviews">
    <div className="container">
      <Slider className="reviews__list" {...slider_settings(3)}>
        {reviews?.map((item, index) => (
          <ReviewCard {...{ review: item, key: index }} />
        ))}
      </Slider>
    </div>
  </section>
);

export default Reviews;

const ReviewCard = ({ review }) => {
  let icon;

  switch (review.resource) {
    case 'Facebook':
      icon = '/assets/images/logos/review_facebook.svg';
      break;
    case 'Yelp':
      icon = '/assets/images/logos/review_yelp.svg';
      break;
    default:
      icon = '/assets/images/logos/review_google.svg';
      break;
  }

  return (
    <div className="reviews__list--card">
      <div className="reviews__list--card__header">
        <div className="reviews__list--card__header--resource">
          <img src={icon} alt={review.resource} />
        </div>
        <div className="reviews__list--card__header--info">
          <span className="reviews__list--card__name">{review.customer_name}</span>
          <span className="reviews__list--card__date">{review.date}</span>
        </div>
      </div>
      <RatingStars />
      <div className="reviews__list--card__body">
        <p>{review.text}</p>
      </div>
    </div>
  );
};
