import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';

import static_content from '../../static/content';

import './form.scss';

const MAIL_URL = 'https://admin.defenderappliancerepair.com/wp-json/contact-form-7/v1/contact-forms/8/feedback';

const BookingForm = ({ prevLocation }) => {
  const navigate = useNavigate();
  const [bookingFrom, setBookingForm] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    type_of_service: '',
    message: '',
  });

  const handleChange = (e) => {
    const key = e.target.name;
    const val = e.target.value;

    setBookingForm((prevState) => ({
      ...prevState,
      [key]: val,
    }));
  };

  const sendEmail = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    Object.entries(bookingFrom).map((el) => formData.append(el[0], el[1]));
    formData.append('_wpcf7_unit_tag', true);

    try {
      const response = await axios.post(MAIL_URL, formData);

      if (response.status === 200) {
        toast.success(response.data.message);
        e.target.reset();
        navigate('/thank-you');
      }
    } catch (error) {
      toast.error('Ooops! Something went wrong! Please, try again!');
      console.log(error);
    }
  };

  useEffect(() => {
    switch (prevLocation) {
      case 'refrigerator-repair':
        setBookingForm((prevState) => ({
          ...prevState,
          type_of_service: 'Refrigerator Repair',
        }));
        break;
      case 'freezer-repair':
        setBookingForm((prevState) => ({
          ...prevState,
          type_of_service: 'Freezer Repair',
        }));
        break;
      case 'dryer-repair':
        setBookingForm((prevState) => ({
          ...prevState,
          type_of_service: 'Dryer Repair',
        }));
        break;
      case 'washer-repair':
        setBookingForm((prevState) => ({
          ...prevState,
          type_of_service: 'Washer Repair',
        }));
        break;
      case 'oven-repair':
        setBookingForm((prevState) => ({
          ...prevState,
          type_of_service: 'Oven Repair',
        }));
        break;
      case 'dishwasher-repair':
        setBookingForm((prevState) => ({
          ...prevState,
          type_of_service: 'Dishwasher Repair',
        }));
        break;
      case 'microwave-repair':
        setBookingForm((prevState) => ({
          ...prevState,
          type_of_service: 'Microwave Repair',
        }));
        break;
      case 'range-repair':
        setBookingForm((prevState) => ({
          ...prevState,
          type_of_service: 'Range Repair',
        }));
        break;
      default:
        setBookingForm((prevState) => ({
          ...prevState,
          type_of_service: '',
        }));
        break;
    }
  }, [prevLocation]);

  return (
    <form onSubmit={sendEmail}>
      <div>
        <input required type="text" placeholder="First Name *" name="first_name" onChange={handleChange} />
      </div>

      <div>
        <input required type="text" placeholder="Last Name *" name="last_name" onChange={handleChange} />
      </div>

      <div>
        <input required type="email" placeholder="Email *" name="email" onChange={handleChange} />
      </div>

      <div>
        <input required type="tel" placeholder="Phone *" name="phone" onChange={handleChange} />
      </div>

      <div className="select-wrapper">
        <select name="type_of_service" required value={bookingFrom.type_of_service} onChange={handleChange}>
          <option hidden value="dump">
            Type of service *
          </option>
          {static_content.services?.map((service, index) => (
            <option key={index} value={service.title}>
              {service.title}
            </option>
          ))}
        </select>
      </div>

      <div>
        <textarea
          required
          placeholder="Please let us know if you have any special requests *"
          name="message"
          onChange={handleChange}
        />
      </div>

      <div>
        <input type="submit" className="btn grey" value="Send" />
      </div>

      <div className="data-protection">
        <Lock /> <span>Data protected and privacy</span>
      </div>
    </form>
  );
};

export default BookingForm;

const Lock = () => (
  <svg
    className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc"
    focusable="false"
    aria-hidden="true"
    viewBox="0 0 24 24"
    data-testid="LockIcon"
  >
    <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z"></path>
  </svg>
);
