import { BookingForm } from '../../components';

import static_content from '../../static/content';

import './whyWe.scss';

const WhyWe = () => {
  const location = window.location.href.split('/')[3];

  return (
    <section className="why-we">
      <div className="container">
        <div className="why-we__inner">
          <div>
            <h2 className="section-title">Why Defender Appliance & Repair?</h2>

            <div className="why-we__reasons">
              {static_content.cards?.map((card, index) => (
                <Card card={card} key={index} />
              ))}
            </div>
          </div>
          <div className="form-wrapper">
            <h4>
              Tell us how we can
              <br />
              help you today
            </h4>
            <BookingForm prevLocation={location} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyWe;

const Card = ({ card }) => (
  <div className="why-we__reasons--card">
    <div className="why-we__reasons--card__title">
      <img src={card.icon} alt={card.title} />

      <h4 dangerouslySetInnerHTML={{ __html: card.title }} />
    </div>

    <p>{card.description}</p>
  </div>
);
