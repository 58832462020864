import { useEffect } from 'react';
import useFetch from 'react-fetch-hook';
import { toast } from 'react-toastify';

import { Layout, Spinner, Page404 } from '../components';

import { ADMIN_URL } from '../App';

const PageNotFound = () => {
  const { isLoading, data, error } = useFetch(`${ADMIN_URL}/page-settings?slug=settings`);

  useEffect(() => {
    if (error) toast.error('Data loading error. Please reload the page!');
  }, [error]);

  if (isLoading) return <Spinner />;

  return (
    <Layout page="error404" settings={data[0].acf}>
      <section>
        <div className="container">
          <Page404 />
        </div>
      </section>
    </Layout>
  );
};

export default PageNotFound;
