import { Link } from 'react-router-dom';

import './footer.scss';

const Footer = ({ contacts, logo }) => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="page-footer">
      <div className="container">
        <Link to="/" className="page-footer__logo">
          <img src={logo} alt="footer logo" />
        </Link>

        <a href={`tel:${contacts?.phone}`} className="page-footer__phone">
          {contacts?.phone}
        </a>

        <a href={`tel:${contacts?.email}`} className="page-footer__email">
          {contacts?.email}
        </a>

        <p className="page-footer__address">{contacts?.address}</p>

        <div className="page-footer__underline">
          <span>©{currentYear} Defender. Appliance Repair. All Rights Reserved.</span>

          <a href="https://toporin.com" target="_blank" rel="noReferrer" className="created-by">
            Designed by <strong>Toporin Studio®</strong> in California
          </a>

          <div className="page-footer__underline--links">
            <a href="/privacy-policy">Privacy Policy</a>
            <a href="/accessibility-statement">Accessibility Statement</a>
            <a href="/terms-of-use">Terms of Use</a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
